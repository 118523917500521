import React from "react";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { GatsbyImage } from "gatsby-plugin-image";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import PropTypes from "prop-types";
import QuoteIcon from "assets/icons/quote.svg";
import * as TitleQuoteStyling from "./TitleQuote.module.scss";
import { graphql } from "gatsby";

const TitleQuote = ({ block }) => {
  const headline = block.title;
  const isImage = block.addImage;
  const image = block.image;
  const structuredText = block.text;

  return (
    <Section className={TitleQuoteStyling.quoteWrapper}>
      <div className="row mt-7 mb-7">
        <div className="col sm-6">
          {isImage && image && (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt=""
              className="mb-5"
            />
          )}
          <p className={`text24 hide-from-s ${TitleQuoteStyling.headline}`}>
            {headline}
          </p>
          <p className="text7 hide-to-s">{headline}</p>
        </div>
        <div className="col sm-6">
          <QuoteIcon className={TitleQuoteStyling.quoteMark} />
          <StructuredTextRenderer
            data={structuredText}
            isParagraphClassName={`text5 c-12 mb-7 ${TitleQuoteStyling.quote}`}
            isHeadingLevelClassName="text4 mb-3"
            isHeadingLevel={5}
          />
        </div>
      </div>
    </Section>
  );
};

export default TitleQuote;

TitleQuote.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.object.isRequired,
    addImage: PropTypes.bool,
    image: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      gatsbyImageData: PropTypes.object.isRequired,
    }),
  }),
};

export const query = graphql`
  fragment TitleQuote on DatoCmsTitlequote {
    model {
      apiKey
    }
    id: originalId
    title
    addImage
    image {
      gatsbyImageData(width: 192)
      alt
      title
    }
    text {
      value
      blocks {
        __typename
        ...Link
      }
    }
  }
`